<template>
    <div>

        
            <v-layout wrap justify-center pt-10  >
              <v-flex xs10>
                <span
                  style="font-family: OutfitSemiBold; font-weight: bold; color: #1A2421; text-align: justify"
                  :style="{
                    'line-height':
                      $vuetify.breakpoint.name == 'xs'
                        ? '20px'
                        : $vuetify.breakpoint.name == 'md'
                        ? '20px'
                        : '50px',
                    'font-size':
                      $vuetify.breakpoint.name == 'xs'
                        ? '30px'
                        : $vuetify.breakpoint.name == 'md'
                        ? '18px'
                        : '30px',
                  }"
                >
                  <span style="color: #1B6DC1;">All</span> Courses
                </span>
              </v-flex>
      
              <v-flex xs12 sm10 md10 lg10 pt-4 px-7 px-lg-0 px-md-0 px-sm-0>
                <span
                  style="font-family: OutfitRegular; color: black; text-align: justify"
                  :style="{
                    'line-height':
                      $vuetify.breakpoint.name == 'xs'
                        ? '20px'
                        : $vuetify.breakpoint.name == 'md'
                        ? '20px'
                        : '30px',
                    'font-size':
                      $vuetify.breakpoint.name == 'xs'
                        ? '16px'
                        : $vuetify.breakpoint.name == 'md'
                        ? '15px'
                        : '15px',
                  }"
                >
                  Transform aspiring learners into knowledgeable experts with our
                  specialized courses, crafted to cultivate skills and ignite
                  curiosity.
                </span>
              </v-flex>
              
              <v-layout wrap justify-end>
                <v-flex xs12 sm6 md5 lg3 pl-8 px-lg-md-0 px-10 pl-sm-16 pt-3 pt-md-8>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                     placeholder="Search Course"
                    outlined
                    dense
                    color="blue"
                    class="blue-outline"
                    style="border-radius: 10px;font-family:OutfitRegular"
                  ></v-text-field>
                </v-flex>
                


                <v-flex
                  pl-lg-5
                  xs12
                  sm6
                  md3
                  lg2
                  px-lg-md-sm-0
                  px-8

                  pt-3
                  pt-md-8
                  pl-lg-md-sm-0
                  
                  justify-end
                >
                <v-btn
             
                style="background-color: #1B6DC1; border-radius: 10px;font-family:OutfitRegular"
                color="white"
                outlined
                @click="toggleDropdown"
              >
                    Filter
                    <v-icon right>mdi-filter</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-layout>
               
          <div v-if="filteredItems.length === 0" style="color: black;font-family: OutfitRegular;font-weight:bold;text-align:center">
            <p>No records found</p>
          </div>

            <!-- Divider -->
            <v-divider class="my-4" color="black" style="width: 83.33%; margin: 0 auto;"></v-divider>
        
            <v-layout wrap justify-start v-if="showDropdown" class="ml-8 ml-lg-16 ml-md-16 ml-sm-16 ml-xl-16"  style="font-family:OutfitRegular"  px-lg-16 px-md-5>
              <v-flex xs11 sm10 md4 lg3 px-0 px-lg-0 >
                
                  <v-subheader style="color: black; font-size:13px;font-family: OutfitRegular;font-weight:bold;margin-left:-15px;">TYPE</v-subheader>
                
                
                <v-select
                  v-model="selectedType"
                  :items="types"
                  :menu-props="{ bottom: true, offsetY: true }"
                  placeholder="Select Type"
                 
                  outlined
                  small
                  dense
                  style="border-radius: 10px;font-family:OutfitRegular"
                  @change="handleTypeChange"
                ></v-select>
              </v-flex>
            
              <v-flex xs11 sm10 md4 lg3 pl-md-2 px-0 px-lg-0 pl-lg-8>
                <v-subheader style="color: black;font-size:13px;font-family: OutfitRegular;font-weight:bold;margin-left:-15px;">CATEGORY</v-subheader>
                <v-select
                  v-model="selectedCategory"
                  :items="filteredCategories"
                   :menu-props="{ bottom: true, offsetY: true }"
                  placeholder="Select Category"
                  outlined
                  small
                  dense
                  style="border-radius: 10px;font-family:OutfitRegular"
                  @change="handleCategoryChange"
                ></v-select>
              </v-flex>
            
              <v-flex xs11 sm10 md4 lg3 pl-md-2 px-0 px-lg-0 pl-lg-8>
                <v-subheader style="color: black;font-size:13px;font-family: OutfitRegular;font-weight:bold;margin-left:-15px;">INSTITUTE</v-subheader>
                <v-select
                  v-model="selectedInstitute"
                  :items="filteredInstitutes"
                   :menu-props="{ bottom: true, offsetY: true }"
                  placeholder="Select Institute"
                  outlined
                  small
                  dense
                  style="border-radius: 10px;font-family:OutfitRegular"
                  @change="filterCourses"
                ></v-select>
              </v-flex>

              <!--  -->

            </v-layout>



        <v-layout wrap justify-center px-5  px-lg-0>

       

          <v-flex xs12 sm12 md12 lg10 pt-lg-5 data-aos="zoom-in-up"
          data-aos-ease="ease"
          data-aos-duration="1500"
          data-aos-delay="500">
    <v-flex xs12 sm12 pb-10 v-for="(item, i) in filteredItems" :key="i">
              <v-card outlined elevation="4" rounded="lg" class="pa-6" >
                <v-layout wrap justify-center>
                  <!-- Image on the left -->
                  <v-flex xs12 sm6 md4 lg3 >
                    <v-img :src="item.img"  height="200px"></v-img>
                  </v-flex>
      
                  <!-- Content on the right -->
                  <v-flex xs12 sm12 md8 lg9>
                    <v-card-title> <span style="font-family: OutfitBold; font-size: 19px; color: black">{{item.title}}</span></v-card-title>
                    <v-card-text>
                     
                    
                      <p style="font-family: JostRegular; color: black; font-size: 15px; text-align:justify">{{item.content}}</p>
                    </v-card-text>
      
                    <v-layout wrap justify-end>
                        <v-flex xs11 sm4 md3 lg2  >
                          <router-link :to="item.route" style="text-decoration: none">
                          <v-btn class="px-5 pa-4" color="primary" small>
                          
                              <span style="color: white; text-transform: capitalize;">Course Details</span>
                           
                          </v-btn>
                        </router-link>
                        </v-flex>
                      </v-layout>
      
                    <!-- Button Section -->
                    <!-- <v-layout wrap justify-start>
                  
                   <v-flex xs2 pb-5>
                        <v-btn class="ml-3 pa-4" color="#E6EFF9" style="border-radius: 8px; background-color: #E6EFF9 !important; color: black !important;" small>
                          <v-icon small color="blue">mdi-clock-outline</v-icon>
                          &nbsp; <span style="color: black; text-transform: capitalize;">{{item.months}}</span>
                        </v-btn>
                      </v-flex>
      
                
                      <v-flex pb-5>
                        <v-btn class="pa-4" color="#E6EFF9" style="border-radius: 8px;" small>
                          <v-icon small color="blue">mdi-book-open-outline</v-icon>
                          &nbsp;<span small style="color: black; text-transform: capitalize;">{{item.chapter}}</span>
                        </v-btn>
                      </v-flex>
      
                     
                      <v-layout wrap justify-end>
                        <v-flex xs1 pb-5>
                          <v-btn class="px-5 pa-4" color="primary" small>
                            <router-link to="/coursesingleview" style="text-decoration: none">
                              <span style="color: white; text-transform: capitalize;">Course Details</span>
                            </router-link>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-flex>
          </v-layout>
      
      
<!-- <v-layout wrap justify-center>
  <v-flex xs11 sm11 md10 v-if="showCarmelCourse">
    <carmelcourse />
  </v-flex>
  <v-flex xs11 sm11 md12 pt-0 v-if="showZenCourse">
    <zencourse />
  </v-flex>
  <v-flex xs11 sm11 md12 pt-0 v-if="showOnlineCourse">
    <onlinecourse />
  </v-flex>
</v-layout> -->

<!-- <v-layout wrap justify-center pt-10>
  <v-flex xs11 sm11 md10 v-if="showZenCourse">
      <zencourse />
  </v-flex> <v-flex xs11 sm11 md12 pt-0 v-if="showOnlineCourse">
      <onlinecourse />
  </v-flex><v-flex xs11 sm11 md12 pt-0 v-if="showCarmelCourse">
      <carmelcourse />
  </v-flex>
</v-layout> -->


    </div>
</template>

<script>

export default {
  components: {
    // carmelcourse,
    // zencourse,
    // onlinecourse,
  },
  data: () => ({
    
    selectedType: '', // Selected type from main dropdown ('Learning' or 'Training')
    selectedCategory: '', // Selected category from nested dropdown
    selectedInstitute: '', // Selected institute from nested dropdown
    sortOrder: '', // Selected sort order
    
    search: '', // Search query entered by the user
    types: ['Learning', 'Training'], // Main dropdown options including 'All'
    learningCategories: ['Languages', 'Computer Courses', 'Accounting', 'Medical Courses'], // Learning categories
    trainingCategories: ['Health & Safety'], // Training categories
    languageInstitutes: ['Rubicon Carmel Courses'], // Institutes for Languages category
    computerInstitutes: ['Rubicon ZenCourses'], // Institutes for Computer Courses category
    showDropdown: false, // Boolean to toggle dropdown visibility
    sortOptions: ['Latest', 'Oldest'], // Sort options

    items: [
        {
          img: require("./../../assets/images/excel.png"),
          title: "Advance Excel Course",
          review: "4.5",
          fullreview: "(73 Reviews)",
          content:
          "This course covers pivot tables, formulas, conditional formatting, Power Query, Power Pivot, and Power BI for data analysis. Participants gain skills in data manipulation, visualization, and reporting, enhancing decision-making abilities. Master Excel's advanced features for insightful data analysis and reporting.",
          months: "3 Months",
          chapter: "32 Chapters",
          route: "othercoursesingleview",
          type: "Learning", // Specify the type for each course
          category: "Computer Courses",
           institute: "Rubicon ZenCourses"
        },
        {
          img: require("./../../assets/images/office.png"),
          title: "Office Automation - Basics and Beyond",
          
          content:
          "Explore the fundamentals and advanced techniques of Office Automation in our dynamic course. Master essential MS Office skills, including document creation, spreadsheet management, and presentation design, unlocking countless opportunities across various industries. Gain the confidence and expertise to streamline workflow in any professional setting."
          ,
          
          route: "/officeautopage",
          category: "Computer Courses",
           institute: "Rubicon ZenCourses",
          type: "Learning" // Specify the type for each course,
        },
        {
          img: require("./../../assets/images/tally.png"),
          title: "Tally Prime - Balance Is The Key",
          category: "Computer Courses",
           institute: "Rubicon ZenCourses",
          content:
            
          "Discover the power of TALLYPRIME in our dynamic course designed for comprehensive business management. From accounting to payroll, delve into its versatile features and learn to optimize operations effortlessly. Empower yourself with the skills to navigate complex financial tasks seamlessly, ensuring your business achieves balance and efficiency in every aspect.",
          
          route: "/tallypage",
          type: "Learning" // Specify the type for each course
        },
        {
          img: require("./../../assets/images/multimedia.png"),
          title: "Multimedia Course",
         category: "Computer Courses",
          institute: "Rubicon ZenCourses",
          content:
          "Master the art of audio and video editing in our intensive certificate course. Learn essential skills in Sound Forge for audio manipulation and Adobe Premiere for video editing through practical exercises. Elevate your editing abilities and unleash your creativity with industry-standard software, setting the stage for professional success in multimedia production."
          ,
          
          route: "/multymediapage",
          
          type: "Learning" // Specify the type for each course
        },
        {
            img: require("./../../assets/images/health.jpg"),
            title: "Health & Safety Online Training",
            
            content:
            "Health and safety is about preventing people from being harmed by work or becoming ill through work and the law applies to all businesses, however large or small. These health and safety training course materials provide required training for all staff members.",
            category: "Health & Safety",
            route: "healthandsafetypage",
            type: "Training" // Specify the type for each course
          },
          {
            img: require("./../../assets/images/manualhandling.jpg"),
            title: "Manual Handling Online Training",
            category: "Health & Safety",
            content:
            "Intended primarily for individuals who perform manual tasks in any workplaces, this course provides crucial insights that not only minimise the risk of injuries but also elevate your proficiency in handling manual tasks.  Don't compromise on your safety and equip yourself with the expertise of manual handling best practices, ensuring a safer and more efficient work environment."
            ,
            
            route: "/manualhandlingpage",
            type: "Training" // Specify the type for each course
          },
          {
            img: require("./../../assets/images/ppe.jpg"),
            title: "Personal Protective Equipment (PPE) online training",
            category: "Health & Safety",
            content:
              
            "Wearing PPE while at work is of great importance. It ensures that should any accidents occur, the damage imposed on the wearer is significantly reduced or prevented altogether. Personal protective equipment is defined as equipment, including those that protect from adverse weather conditions, that is intended to be worn or held by a person at work.",
            
            route: "/ppepage",
            
            type: "Training" // Specify the type for each course
          },
          {
            img: require("./../../assets/images/lader.jpg"),
            title: "Ladder safety online Training",
            category: "Health & Safety",
            content:
            "The ladder safety training program will help employers ensure they are meeting their legal duties to protect from the hazards of ladder use. The course provides a fundamental awareness of setting up, ascending, descending, working from, and storing ladders"
            ,
            
            route: "/ladderpage",
            type: "Training" // Specify the type for each course
          },

          {
            img: require("./../../assets/images/height.jpg"),
            title: "Working at Heights online Training",
           
            content:
            "The course  covers working safely at heights in resources and infrastructure industries. It includes: identifying the work requirements, work procedures and instructions for the task; accessing and installing equipment; performing work at heights; and cleaning up the work area."
            ,
            
            route: "/heightpage",
            type: "Training" ,// Specify the type for each course
            category: "Health & Safety",
          },
          {
            img: require("./../../assets/images/ger.png"),
            title: "German course - LEVELS A1-B2",
            // review: "4.5",
            // fullreview: "(73 Reviews)",
            content:
              "Achieve fluency in German through our meticulously crafted four-step language course, guiding you from foundational communication to advanced proficiency. Develop confidence in navigating daily scenarios, explore topics such as city life and holidays, and refine persuasive speech to enhance your linguistic expertise.",
            // months: "3 Months",
            // chapter: "32 Chapters",
            type: "Learning", // Specify the type for each course
            category: "Languages",
            institute: "Rubicon Carmel Courses",
            route: "/coursesingleview",
            
          },
       
        
      ],
  }),
  computed: {
    filteredCategories() {
      if (this.selectedType === 'Learning') {
        return this.learningCategories;
      } else if (this.selectedType === 'Training') {
        return this.trainingCategories;
      }
      return [];
    },
    filteredInstitutes() {
      if (this.selectedCategory === 'Languages') {
        return this.languageInstitutes;
      } else if (this.selectedCategory === 'Computer Courses') {
        return this.computerInstitutes;
      }
      return [];
    },
    filteredItems() {
      let filteredList = this.items;

      // Filter by selectedType
      if (this.selectedType !== 'All' && this.selectedType !== '') {
        filteredList = filteredList.filter(item => item.type === this.selectedType);
      }

      // Filter by selectedCategory
      if (this.selectedCategory && this.selectedCategory !== 'All' && this.selectedCategory !== '') {
        filteredList = filteredList.filter(item => item.category === this.selectedCategory);
      }

      // Filter by selectedInstitute
      if (this.selectedInstitute && this.selectedInstitute !== 'All' && this.selectedInstitute !== '') {
        filteredList = filteredList.filter(item => item.institute === this.selectedInstitute);
      }

      // Filter by search query (title)
      if (this.search.trim() !== '') {
        const searchTerm = this.search.trim().toLowerCase();
        filteredList = filteredList.filter(item => item.title.toLowerCase().includes(searchTerm));
      }

      // Sort by sortOrder
      if (this.sortOrder === 'Latest') {
        filteredList = filteredList.sort((a, b) => new Date(b.date) - new Date(a.date)); // Assuming 'date' field in items
      } else if (this.sortOrder === 'Oldest') {
        filteredList = filteredList.sort((a, b) => new Date(a.date) - new Date(b.date)); // Assuming 'date' field in items
      }

      return filteredList;
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown; // Toggle dropdown visibility
    },
    handleTypeChange() {
      this.selectedCategory = ''; // Reset selectedCategory and selectedInstitute when type changes
      this.selectedInstitute = '';
    },
    handleCategoryChange() {
      this.selectedInstitute = ''; // Reset selectedInstitute when category changes
    },
    filterCourses() {
      // Triggered when search or dropdown values change
    },
    sortCourses() {
      // Placeholder for sorting logic if needed
    },
  }
}
</script>

<style>
.v-list-item__title {
  font-family: 'OutfitRegular';
  color: black;

  font-weight: bold;
}
</style>


